<template>
    <page-header-wrapper>
        <template v-slot:content>
            <div class="page-header-content">
                <a-card :bordered="false" class="content">
                    <div class="table-page-search-wrapper">
                        <a-form layout="inline">
                            <a-row :gutter="48">
                                <a-col :md="6" :sm="18">
                                    <a-form-item label="岗位名称">
                                        <a-input placeholder="请输入" v-model="queryParams.jobName" />
                                    </a-form-item>
                                </a-col>
                                <a-col :md="6" :sm="18">
                                    <span class="table-page-search-submitButtons">
                                        <a-button @click="handleQuery" type="primary">查询</a-button>
                                        <a-button @click="resetQuery" style="margin-left: 8px">重置</a-button>
                                    </span>
                                </a-col>
                            </a-row>
                        </a-form>
                    </div>
                    <div class="table-page-operator-wrapper">
                        <a-button
                            @click="handleAddClick"
                            type="primary"
                            v-hasPermi="['workflow:job:add']"
                            ghost
                            >新增</a-button
                        >
                    </div>
                    <!-- 表格 -->
                    <a-table
                        ref="table"
                        :columns="columns"
                        :loading="tableLoading"
                        :data-source="jobList"
                        row-key="jobId"
                        :pagination="false"
                    >
                        <!-- 插槽指向状态 -->
                        <span slot="status" slot-scope="text, record">
                            <a-popconfirm
                                ok-text="是"
                                cancel-text="否"
                                @confirm="confirmHandleStatus(record)"
                                @cancel="cancelHandleStatus(record)"
                            >
                                <span slot="title">确认<b>{{ record.status === '1' ? '启用' : '停用' }}</b>名称为:{{ record.nickName }}的岗位吗?</span>
                                <a-switch checked-children="开" un-checked-children="关" :checked="record.status == 0" />
                            </a-popconfirm>
                        </span>
                        <!-- 更多选择 -->
                        <span slot="action" slot-scope="text, record">
                            <a
                                @click="$refs.createModal.show(record, true)"
                                v-hasPermi="['organization:class:query']"
                                >详细</a
                            >
                            <a-divider type="vertical" />
                            <a
                                @click="$refs.createModal.show(record, false)"
                                v-hasPermi="['organization:class:edit']"
                                >编辑</a
                            >
                            <a-divider type="vertical" />
                            <a
                                href="javascript:;"
                                v-hasPermi="['organization:class:remove']"
                                @click="handleDelete(record)"
                                >删除</a
                            >
                        </span>
                    </a-table>
                    <!-- 底部分页按钮 -->
                    <a-pagination v-if="false"
                        class="ant-table-pagination"
                        v-model="current"
                        :page-size-options="pageSizeOptions"
                        :total="total"
                        show-size-changer
                        :page-size="pageSize"
                        @showSizeChange="onShowSizeChange"
                        @change="currentPageChange"
                    >
                        <template slot="buildOptionText" slot-scope="props">
                            <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
                            <span v-if="props.value === '50'">全部</span>
                        </template>
                    </a-pagination>
                    <!-- 创建/编辑部门,单独封装了组件 -->
                    <create-form ref="createModal" @handle-success="handleOk" />
                </a-card>
            </div>
        </template>
    </page-header-wrapper>
</template>

<script>
// import { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import { listJob,delJob,changeJobStatus } from '@/api/rongyi/workflow/job'
import CreateForm from './modules/CreateForm'
const statusMap = {
    0: {
        status: 'processing',
        text: '正常',
    },
    1: {
        status: 'default',
        text: '停用',
    },
}
export default {
    name: 'job',
    components: {
        CreateForm,
    },
    data() {
        return {
            // 分页数据(默认第一页):
            // pageSizeOptions: ['10', '20', '30', '40', '50'],
            // current: 1,
            // pageSize: 10,
            // total: 0,
            statusMap: [],
            tableLoading: false,
            jobList:[],
            // 状态数据字典
            statusOptions: [],
            // 查询参数
            queryParams: {
                jobName:'',
            },
            // 表头
            columns: [
                {
                    title: '名称',
                    dataIndex: 'jobName',
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    scopedSlots: { customRender: 'status' },
                },
                {
                    title: '操作',
                    width: '150',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' },
                },
            ],
        }
    },
    // 状态过滤
    filters: {
        statusFilter(type) {
            return statusMap[type].text
        },
        statusTypeFilter(type) {
            return statusMap[type].status
        },
    },
    created() {
        this.getJobList();
    },
    methods: {
        getJobList(){
            
            this.tableLoading = true
            listJob()
                .then(res => {
                this.jobList = res.rows
                this.tableLoading = false
            })
        },
        // 字典状态字典翻译
        statusFormat(row, column) {
            return this.selectDictLabel(this.statusOptions, row.status)
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.getJobList()
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm('queryForm')
            this.handleQuery()
        },
        handleAddClick(){
            this.$refs.createModal.show(null, false);
            
        },
        // 新增/修改框事件
        handleOk() {
            this.getJobList()
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const that = this
            this.$confirm({
                title: '警告',
                content: `真的要删除 名为${row.jobName}的数据项吗?`,
                okText: '删除',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    delJob(row.jobId).then((response) => {
                        if (response.code === 200) {
                            that.$message.success('删除成功!')
                            that.getJobList()
                        } else {
                            that.$message.error(response.msg)
                        }
                    })
                },
            })
        },
        /* 任务状态修改 */
        confirmHandleStatus (row) {
            row.status = row.status === '0' ? '1' : '0'
            const text = row.status === '0' ? '启用' : '停用'
            changeJobStatus(row.jobId, row.status)
            .then(() => {
                this.$message.success(
                text + '成功',
                3
                )
            }).catch(function () {
                this.$message.error(
                text + '发生异常',
                3
                )
            })
        },
        cancelHandleStatus (row) {
        }
    },
}
</script>